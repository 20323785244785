import React from 'react'
import styled from 'styled-components'

import media from '@styles/media'

import RRLogo from '../../assets/richrosales.svg'

const LogoSymbolContainer = styled.div`
  height: 67px;
  width: 114px;
`
/**
 * An inline SVG for Monarck Logo with aria labels
 *
 * @param {String} fill dark or light
 */
const Logo = ({
  fill = '#fff',
  onlySymbol = false,
}: {
  fill?: string
  onlySymbol?: boolean
}) => {
  if (onlySymbol) {
    return (
      <LogoSymbolContainer>
        <img src={RRLogo} alt="Rich Rosales" width="100%" height="auto" />
      </LogoSymbolContainer>
    )
  }

  return (
    <LogoSymbolContainer>
      <img src={RRLogo} alt="Rich Rosales" width="100%" height="auto" />
    </LogoSymbolContainer>
  )
}

export default Logo
